import React, { useEffect } from 'react';
import * as S from './location-button.style';
import { Icon } from '@components/ui/icons/icon';
import { useStore } from '@stores/root';
import { observer } from 'mobx-react-lite';
import { Button } from '@components/ui/button/button';
import { AnimatePresence } from 'framer-motion';
import { motionOpacity } from '@components/ui/animations/animations';
import { NoSSR } from '@components/NoSSR';
import { WH } from '@components/WH';

type Props = {
  hidePopup?: boolean;
};

export const LocationButton = observer((props: Props) => {
  const { modal, geo, project } = useStore();

  const openGeoModal = () => {
    modal.show('geo');
  };

  const onAccept = () => {
    geo.acceptLocation();
  };

  const onDecline = () => {
    openGeoModal();
  };

  const withoutCity = () => {
    geo.setWithout(true);
  };

  return (
    <S.LocationButtonContainer>
      <S.LocationButton onClick={openGeoModal}>
        <S.LocationButtonIcon>
          <Icon icon="mapPin" />
        </S.LocationButtonIcon>

        <S.LocationButtonText>
          <WH>Кумертау</WH>
        </S.LocationButtonText>
      </S.LocationButton>
      {/* <NoSSR> */}
      {/*   <AnimatePresence> */}
      {/*     {!geo.without && !props.hidePopup && geo.slug === null && ( */}
      {/*       <S.LocationButtonPopup> */}
      {/*         <S.LocationButtonPopupTitle>Выберите ваш город</S.LocationButtonPopupTitle> */}
      {/*         <S.LocationButtonPopupButtons> */}
      {/*           <Button onClick={onDecline}>Выбрать</Button> */}
      {/*         </S.LocationButtonPopupButtons> */}

      {/*         <S.ContinueWithout onClick={withoutCity}>Продолжить без выбора</S.ContinueWithout> */}
      {/*       </S.LocationButtonPopup> */}
      {/*     )} */}

      {/*     {!props.hidePopup && geo.conflict && ( */}
      {/*       <S.LocationButtonPopup> */}
      {/*         <S.LocationButtonPopupTitle>Ваш город определился верно?</S.LocationButtonPopupTitle> */}
      {/*         <S.LocationButtonPopupButtons> */}
      {/*           <Button onClick={onAccept}>Да</Button> */}
      {/*           <Button onClick={onDecline} secondary> */}
      {/*             Нет */}
      {/*           </Button> */}
      {/*         </S.LocationButtonPopupButtons> */}
      {/*       </S.LocationButtonPopup> */}
      {/*     )} */}
      {/*   </AnimatePresence> */}
      {/* </NoSSR> */}
    </S.LocationButtonContainer>
  );
});
